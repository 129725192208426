import useSWR, { KeyedMutator } from 'swr'
import { useMemo } from 'react'
import { endpoints, fetcher, poster } from 'src/utils/axios'
import { PaymentMethod, StripeCustomCheckoutTaxAmount } from '@stripe/stripe-js'
import logger from 'src/utils/logger'
import { CustomError } from 'src/api/utils'

import { getCurrentOrgId } from 'src/utils/local_storage'
import { ExtendedAccountDocument } from '../../../dto/accounts/AccountInterface'
import { StripeAddress, StripeCustomer } from '../../../dto/accounts/StripeInterface'
import { StandardResponse } from '../../../models/utils/UtilsInterface'

// ----------------------------------------------------------------------

interface UseListAccountsResult {
  accounts: ExtendedAccountDocument[]
  isLoading: boolean
  accountsError: boolean
  accountsValidating: boolean
  accountsEmpty: boolean
  accountsErrorMessage: string
  accountsMutator: KeyedMutator<ExtendedAccountDocument>
}
interface UseGetAccountResult {
  account: ExtendedAccountDocument
  accountLoading: boolean
  accountError: boolean
  accountValidating: boolean
  accountEmpty: boolean
  accountErrorMessage: string
}

// This return only one account for the organization
export function useListAccountsByOrgId(): UseListAccountsResult {
  const currentOrgId = getCurrentOrgId()
  const URL = endpoints.account.accountByOrgId

  const { data, error, isLoading, isValidating, mutate } = useSWR([URL, { organizationId: currentOrgId }], fetcher)

  const memoizedValue = useMemo<UseListAccountsResult>(
    () => ({
      accounts: data?.publicAccounts || [],
      isLoading,
      accountsError: error,
      accountsValidating: isValidating,
      accountsEmpty: !isLoading && !data?.publicAccounts.length,
      accountsErrorMessage: error?.message,
      accountsMutator: mutate,
    }),
    [data?.publicAccounts, error, isLoading, isValidating, mutate]
  )

  return memoizedValue
}

type AvailableAccountsReturnType = {
  availableAccounts: ExtendedAccountDocument[]
  isLoading: boolean
  isError: boolean
  errorMessage?: string
  availableAccountsMutator: KeyedMutator<ExtendedAccountDocument[]>
}

export function useAvailableActiveAccountForPrograms(): AvailableAccountsReturnType {
  const currentOrgId = getCurrentOrgId()
  const URL = endpoints.account.availableActiveAccountsForProgramCreation

  const { data, error, isLoading, mutate } = useSWR<ExtendedAccountDocument[], CustomError>([
    URL,
    {
      organizationId: currentOrgId,
    },
  ])

  const response = {
    availableAccounts: (data ?? []) as ExtendedAccountDocument[],
    isLoading,
    isError: !!error,
    errorMessage: error?.error as string,
    availableAccountsMutator: mutate,
  }

  return response
}

export function useGetAccount({ accountId }: { accountId: string }): UseGetAccountResult {
  const URL = endpoints.account.getAccount
  const params = { accountId }

  const { data, error, isLoading, isValidating } = useSWR([URL, params], fetcher)
  const memoizedValue = useMemo<UseGetAccountResult>(
    () => ({
      account: data?.extendedAccount,
      accountLoading: isLoading,
      accountError: error,
      accountValidating: isValidating,
      accountEmpty: !isLoading && !data?.extendedAccount,
      accountErrorMessage: error?.message,
    }),
    [data?.extendedAccount, error, isLoading, isValidating]
  )

  return memoizedValue
}
export async function updateBillingAddressDetails({
  accountId,
  billingAddress,
  phone,
  organizationName,
  email,
}: {
  accountId: string
  billingAddress: StripeAddress
  phone: string | undefined
  organizationName: string
  email?: string
}): Promise<boolean> {
  try {
    const URL = endpoints.account.updateBillingAddress
    const params = { accountId, billingAddress, phone, organizationName, email }

    const response = await poster([URL, params])

    return response
  } catch (error) {
    logger.error('API call error in updateBillingAddressDetails:', { accountId, billingAddress }, error)
    return false
  }
}

interface UpdateCoversNewProgramsResults {
  success: boolean
  message: string
  error: boolean
  errorMessage: string
}

export async function updateCoversNewPrograms({
  accountId,
  coversNewPrograms,
}: {
  accountId: string
  coversNewPrograms: boolean // Add the necessary input data
}): Promise<UpdateCoversNewProgramsResults> {
  try {
    const URL = endpoints.account.updateCoversNewPrograms // Update with the correct URL
    const params = { accountId, coversNewPrograms }

    const response = await poster([URL, params])
    if (response.success) {
      return {
        success: true,
        message: response.message,
        error: false,
        errorMessage: '',
      }
    }
    // Handle non-successful responses (e.g., 4xx, 5xx)
    throw new Error(response.error.message)
  } catch (error) {
    logger.error('API call error in updateCoversNewPrograms:', { accountId, coversNewPrograms }, error as Error)
    return {
      success: false,
      message: '',
      error: true,
      errorMessage: 'Failed to update coversNewPrograms',
    }
  }
}

export async function updateAccountAdminsApiCall({
  accountId,
  adminIds,
}: {
  accountId: string
  adminIds: string[] // An array of user IDs or admin data
}): Promise<boolean> {
  try {
    const URL = endpoints.account.updateAdmins
    const params = { accountId, adminIds }

    const response = await poster([URL, params])

    return response
  } catch (error) {
    logger.error('updateAccountAdminsApiCall', { accountId, adminIds }, error as Error)
    return false
  }
}

export async function updateAccountManagersApiCall({
  accountId,
  managerIds,
}: {
  accountId: string
  managerIds: string[]
}): Promise<boolean> {
  try {
    const URL = endpoints.account.updateManagers
    const params = { accountId, managerIds }

    const response = await poster([URL, params])

    return response
  } catch (error) {
    logger.error('updateAccountManagersApiCall', { accountId, managerIds }, error as Error)
    return false
  }
}

export async function updateAccountOwnerAPICall({
  accountId,
  newAccountOwnerId,
}: {
  accountId: string
  newAccountOwnerId: string // The user ID of the new owner
}): Promise<boolean> {
  try {
    const URL = endpoints.account.updateOwner
    const params = { accountId, newAccountOwnerId }

    const response = await poster([URL, params])

    return response
  } catch (error) {
    logger.error('updateAccountOwnerAPICall', { accountId, newAccountOwnerId }, error as Error)
    return false
  }
}

export interface CreateAccountResponse {
  accountId: string
  success: boolean
  error: boolean
  errorMessage: string
}

export async function createAccountAPICall({
  customerInfo,
  planId,
  organizationId,
}: {
  customerInfo: Partial<StripeCustomer>
  planId: string
  organizationId: string
}): Promise<CreateAccountResponse> {
  try {
    const URL = endpoints.account.createAccount
    const params = { customerInfo, planId, organizationId }

    const response = (await poster([URL, params])) as CreateAccountResponse

    return response
  } catch (error) {
    logger.error('error in createAccountAPICall', { planId, customer: customerInfo.email }, error as Error)
    return {
      accountId: '',
      success: false,
      error: true,
      errorMessage: 'Frontend create accountAPI failed',
    }
  }
}
export interface InitializeCheckoutResponse {
  subTotal: number
  total: number
  taxRates?: StripeCustomCheckoutTaxAmount[]
  discount: number
  orgName?: string
  phoneNumber?: string
  billingAddress?: StripeAddress
  customerEmail?: string
  planId?: string
  stripePaymentMethod?: PaymentMethod
  activeUsersInPastMonth: number
  hasActiveSubscription: boolean
  isTrialing: boolean
  success: boolean
  error: boolean
  errorMessage: string
}

export async function initializeCheckoutForAccountAPI({
  accountId,
  couponCode,
  planId,
  setupIntentId,
  paymentIntentId,
}: {
  accountId?: string
  couponCode?: string
  planId?: string
  setupIntentId?: string
  paymentIntentId?: string
}): Promise<InitializeCheckoutResponse> {
  try {
    const URL = endpoints.account.initializeCheckout
    const params = { accountId, couponCode, planId, setupIntentId, paymentIntentId }

    const response = (await poster([URL, params])) as InitializeCheckoutResponse

    return response
  } catch (error) {
    logger.error(
      'error in initializeCheckoutForAccountAPI',
      { accountId, couponCode, planId, setupIntentId, paymentIntentId },
      error as Error
    )
    return {
      total: 0,
      discount: 0,
      subTotal: 0,
      orgName: '',
      phoneNumber: '',
      billingAddress: undefined,
      customerEmail: '',
      planId: '',
      activeUsersInPastMonth: 1,
      stripePaymentMethod: undefined,
      hasActiveSubscription: false,
      success: false,
      error: true,
      isTrialing: false,
      errorMessage: 'Frontend initialize checkout API failed',
    }
  }
}

interface UpdateSubscriptionResponse {
  success: boolean
  error: boolean
  clientSecret: string
  status: string
  errorMessage?: string
}

export async function updateSubscriptionAPICall({
  accountId,
  newPlanId,
  paymentMethodId,
}: {
  accountId: string
  newPlanId: string
  paymentMethodId?: string
}): Promise<UpdateSubscriptionResponse> {
  try {
    const URL = endpoints.account.updateSubscription
    const params = { accountId, newPlanId, paymentMethodId }

    const response = (await poster([URL, params])) as UpdateSubscriptionResponse

    return response
  } catch (error) {
    logger.error('error in updateSubscriptionAPICall', { accountId, newPlanId, paymentMethodId }, error as Error)
    return {
      success: false,
      error: true,
      clientSecret: '',
      status: 'There was an error updating the subscription',
      errorMessage: 'Failed to update subscription',
    }
  }
}

interface UpdatePaymentMethodResponse {
  success: boolean
  error: boolean
  clientSecret: string
  status: string
  errorMessage?: string
}

export async function updatePaymentMethodAPICall({
  accountId,
  paymentMethodId,
}: {
  accountId: string
  paymentMethodId?: string
}): Promise<UpdatePaymentMethodResponse> {
  try {
    const URL = endpoints.account.updatePaymentMethod
    const params = { accountId, paymentMethodId }

    const response = (await poster([URL, params])) as UpdatePaymentMethodResponse

    return {
      ...response,
      success: true,
      error: false,
    }
  } catch (error) {
    logger.error('error in updatePaymentMethodAPICall', { accountId, paymentMethodId }, error as Error)
    return {
      success: false,
      error: true,
      errorMessage: 'Failed to update payment method',
      clientSecret: '',
      status: 'failed',
    }
  }
}

interface useGetBillingErrorForOrganizationResult {
  isBillingError: boolean
  BillingErrorLoading: boolean
  BillingErrorError: boolean
  BillingErrorErrorMessage: string
}

export function useGetBillingErrorForOrganization(): useGetBillingErrorForOrganizationResult {
  const currentOrgId = getCurrentOrgId()

  const params = currentOrgId ? { organizationId: currentOrgId } : {}
  const URL = endpoints.account.getBillingErrorsForOrganization

  const { data, error, isLoading } = useSWR([URL, params], fetcher)

  const memoizedValue = useMemo<useGetBillingErrorForOrganizationResult>(
    () => ({
      isBillingError: data?.isError,
      BillingErrorLoading: isLoading,
      BillingErrorError: error,
      BillingErrorErrorMessage: error?.message,
    }),
    [data?.isError, error, isLoading]
  )

  return memoizedValue
}

export async function cancelSubscriptionAPICall({
  accountId,
  cancelationReasons,
  additionalFeedback,
}: {
  accountId: string
  cancelationReasons: string[]
  additionalFeedback: string
}): Promise<StandardResponse> {
  try {
    const URL = endpoints.account.cancelSubscription
    const params = { accountId, cancelationReasons, additionalFeedback }

    const response = (await poster([URL, params])) as StandardResponse

    return response
  } catch (error) {
    logger.error(
      'error in CancelSubscriptionAPICall',
      { accountId, cancelationReasons, additionalFeedback },
      error as Error
    )
    return {
      success: false,
      error: true,
      errorMessage: 'Failed to cancel subscription',
    }
  }
}

export async function abortCancelSubscriptionAPICall({ accountId }: { accountId: string }): Promise<StandardResponse> {
  try {
    const URL = endpoints.account.abortCancelSubscription
    const params = { accountId }

    const response = (await poster([URL, params])) as StandardResponse

    return response
  } catch (error) {
    logger.error('error in abortCancelSubscriptionAPICall', { accountId }, error as Error)
    return {
      success: false,
      error: true,
      errorMessage: 'Failed to abort canceled subscription',
    }
  }
}
